<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Spinner from "vue-simple-spinner";
import { updateBeneficiaryAccount, getBeneficiary } from "@/state/helpers";
import {
  UPDATE_BENEFICIARY_ACCOUNT,
  FETCH_BENEFICIARY_TABLE,
  FETCH_BANKS_LIST
} from "@/state/actions.type";
import VuePhoneNumberInput from "vue-phone-number-input";
import { required, email, helpers, requiredIf } from "vuelidate/lib/validators";
const alphAndSpaceValidator = helpers.regex('alphaAndSpace', /^[a-zA-Z ]*$/);
export default {
  components: {
    Layout,
    PageHeader,
    Spinner,
    VuePhoneNumberInput
  },
  data() {
    return {
      uuid: "",
      errorMessage: "",
      successfulMessage: false,
      createError: false,
      advancedFilterClosed: true,
      submitted: false,
      editModel: false,
      deleteModel: false,

      chaiEditVendor: {
        payout_channel: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        account_no: "",
        account_name: "",
        bank_name: "",
        account_type: "",
        bank_no: "",
        fee_payer: "SENDER"
      },
      phoneNumberVal: null,
      phoneNumber: "",
      accountData: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filters: {
        pmt_channel: [],
        pmt_method: [],
        status: [],
      },
      mor_filter: "",
      filterOn: [],
      sortBy: "date",
      sortDesc: true,

      fields: [
        {
          key: "account_number",
          sortable: true,
          tooltip: "views.payouts.table.tooltip.account_number",
          label: "views.payouts.table.fields.account_number",
        },
        {
          key: "account_name",
          sortable: true,
          label: "views.payouts.table.fields.account_name",
          tooltip: "views.payouts.table.tooltip.account_name",
        },
        {
          key: "bank_no",
          sortable: true,
          label: "views.payouts.table.fields.bank_no",
          tooltip: "views.payouts.table.tooltip.bank_no",
        },
        {
          key: "bank_name",
          sortable: true,
          label: "views.payouts.table.fields.bank_name",
          tooltip: "views.payouts.table.tooltip.bank_name",
        },
      ],
    };
  },
  created() {
    this.uuid = this.$route.query.uuid;
    this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE}`, {
      pageIndex: 1,
      pagesize: 100,
      filters: this.filters,
    })
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },

    title() {
      return this.$t("menuitems.payouts.list.vendor_bank");
    },

    items() {
      return [
        {
          text: this.$t("menuitems.payouts.list.payouts")
        },
        {
          text: this.$t("menuitems.payouts.list.beneficiary"),
          href: '/payouts/vendors'
        },
        {
          text: this.$t("views.payouts.bank_account"),
          active: true,
        },
      ];
    },

    beneficiaryData() {
      return this.$store.state.beneficiary.content.find((e) => e.uuid === this.uuid);
    },
    isFetchingTransactions() {
      return this.$store.state.beneficiary.isFetchingData;
    },

    banksList() {
      return this.$store.state.beneficiary.banks_list;
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },
  },
  watch: {
    perPage: function (val) {
      this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE}`, {
        pageIndex: this.currentPage,
        pagesize: val,
        filters: this.filters,
      });
    },
    currentPage: function (val) {
      this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE}`, {
        pageIndex: val,
        pagesize: this.perPage,
        filters: this.filters,
      });
    },
    'chaiEditVendor.payout_channel': function(value) {
      if(value && !this.isMasterMerchant) {
        this.$store.dispatch(`beneficiary/${FETCH_BANKS_LIST}`, value);
      }
      if(value == 'GBPRIMEPAY_PAYOUT') {
        this.chaiEditVendor.account_type = 'BANK';
      }
    },
    beneficiaryData: function() {
      this.chaiEditVendor.payout_channel = this.beneficiaryData.payout_channel;
      this.chaiEditVendor.name = this.beneficiaryData.name;
      this.chaiEditVendor.email = this.beneficiaryData.email;
      this.chaiEditVendor.phone = this.beneficiaryData.phone;
      this.chaiEditVendor.address = this.beneficiaryData.address;
      this.accountData = this.beneficiaryData.bank_accounts;
    },
    phoneNumberVal: function (val) {
      this.chaiEditVendor.phone = (val && val.countryCallingCode && val.phoneNumber) ? `+${val.countryCallingCode}${val.phoneNumber.replace(/ /g,'')}` : "";
    },
  },

  methods: {
    ...updateBeneficiaryAccount,
    ...getBeneficiary,

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.resetBankAccount();
      this.submitted = false;
      this.editModel = false;
    },

    /**
     * Filter the data of search
     */
    resetNotificationError() {
      this.$store.dispatch("notification/clear");
    },

    opeEditModal() {
      this.resetBankAccount();
      this.submitted = false;
      this.resetNotificationError();
      this.editModel = true;
    },

    editVendor() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || (this.phoneNumberVal && !this.phoneNumberVal.isValid)) {
        return;
      }
      const addAccount = {
        payout_channel: this.beneficiaryData.payout_channel,
        name: this.chaiEditVendor.name,
        email: this.chaiEditVendor.email,
        phone: this.chaiEditVendor.phone,
        address: this.chaiEditVendor.address,
        account_no: this.chaiEditVendor.account_no,
        account_name: this.chaiEditVendor.account_name,
        bank_name: this.chaiEditVendor.bank_name,
        bank_no: this.chaiEditVendor.bank_no,
        account_type: this.chaiEditVendor.account_type,
        fee_payer: this.chaiEditVendor.payout_channel == 'APPOTAPAY_PAYOUT' ? this.chaiEditVendor.fee_payer : "SENDER",
      };

      this.$store.dispatch(`beneficiary/${UPDATE_BENEFICIARY_ACCOUNT}`, {
        uuid: this.uuid,
        addAccount,
      }).then(() => {
        this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE}`, {
          pageIndex: 1,
          pagesize: 100,
          filters: this.filters,
        })
        this.submitted = false;
        this.editModel = false;
        this.chaiEditVendor.account_type = "";
        this.resetBankAccount();
      })
      .catch((error) => {
        const errorObject = JSON.parse(error.message)
        this.$notify({
          type: "error",
          text: errorObject.message,
          closeOnClick: true,
        });
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.chaiEditVendor[name];
      return $dirty ? !$error : null;
    },

    resetBankAccount() {
      if(this.chaiEditVendor.payout_channel !== 'GBPRIMEPAY_PAYOUT') {
        this.chaiEditVendor.account_type = "";
      }

      this.chaiEditVendor = {
        payout_channel: this.beneficiaryData.payout_channel,
        name: this.beneficiaryData.name,
        email: this.beneficiaryData.email,
        phone: this.beneficiaryData.phone,
        address: this.beneficiaryData.address,
        account_no: "",
        account_name: "",
        bank_name: "",
        account_type: this.chaiEditVendor.account_type,
        bank_no: "",
        fee_payer: "SENDER"
      };

      this.phoneNumberVal = null;
      this.phoneNumber = "";
    },

    selectbankcode(){
      this.chaiEditVendor.bank_no = event.target.options[event.target.selectedIndex].dataset.key;
    },
    accNumberLabel() {
      if(this.chaiEditVendor.payout_channel === 'BBL_PAYOUT') {
        if(this.chaiEditVendor.account_type === 'PROMPTPAY_NATID') {
          return 'Promptpay National ID';
        } else if(this.chaiEditVendor.account_type === 'PROMPTPAY_MOBILE'){
          return 'Promptpay Mobile number';
        }
      }
      return this.chaiEditVendor.account_type == 'CARD' ? 'Card Number' : this.$t("views.payouts.bank-acc-no")
    },
    accNumberError() {
      if(this.chaiEditVendor.payout_channel === 'BBL_PAYOUT') {
        if(this.chaiEditVendor.account_type === 'PROMPTPAY_NATID') {
          return 'Vendors Promptpay National ID is required';
        } else if(this.chaiEditVendor.account_type === 'PROMPTPAY_MOBILE'){
          return 'Vendors Promptpay Mobile number is required';
        }
      }
      return this.chaiEditVendor.account_type == 'CARD' ? 'Vendors Card Number is required' : 'Vendors Bank Account Number is required';
    },
    accNameLabel() {
      if(this.chaiEditVendor.payout_channel === 'BBL_PAYOUT') {
        if(this.chaiEditVendor.account_type === 'PROMPTPAY_NATID') {
          return 'Promptpay National ID Name';
        } else if(this.chaiEditVendor.account_type === 'PROMPTPAY_MOBILE'){
          return 'Promptpay Account Name';
        }
      }
      return this.chaiEditVendor.account_type == 'CARD' ? 'Card Holder Name' : this.$t("views.payouts.bank-acc-name")
    },
    accNameError() {
      if(this.chaiEditVendor.payout_channel === 'BBL_PAYOUT') {
        if(this.chaiEditVendor.account_type === 'PROMPTPAY_NATID') {
          return 'Vendors Promptpay National ID Name is required';
        } else if(this.chaiEditVendor.account_type === 'PROMPTPAY_MOBILE'){
          return 'Vendors Promptpay Account Name is required';
        }
      }
      return this.chaiEditVendor.account_type == 'CARD' ? 'Vendors Card Holder Name is required' : 'Vendors Bank Account Name is required';
    }
  },
  validations: {
    chaiEditVendor: {
      account_type: { required },
      name: { required },
      email: { email },
      address: { },
      account_no: { required },
      account_name: { required,alphAndSpaceValidator },
      bank_name: { requiredIf: requiredIf(function (nestedModel) {
        return !['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(nestedModel.account_type)
      })},
      bank_no: { requiredIf: requiredIf(function (nestedModel) {
        return !['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(nestedModel.account_type)
      })},
      phone: { required },
      fee_payer: { required }
    },
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-alert
      class="mt-3"
      v-if="notification.message"
      show
      dismissible
      @dismissed="resetNotificationError"
    >
      {{ notification.message }}</b-alert
    >

    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="">
            <button
              v-if="!isMasterMerchant"
              class="btn btn-primary btn-sm pt-1 mr-3"
              @click="opeEditModal"
            >
              {{ $t("views.payouts.add-account") }}
            </button>

            <div class="d-flex flex-row justify-content-between mt-4 mb-2">
              <div
                class="d-flex flex-row justify-content-start"
                style="display: none"
              >
                <div id="tickets-table_length" class="dataTables_length">
                  <!-- <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Entries
                  </label> -->
                </div>
              </div>

              <!-- TODO: Custom search by account number -->
              <!-- <div class="col-sm-12 col-md-4">
                <b-input-group>
                  <b-form-input
                    type="search"
                    class="form-control form-control-sm ml-2"
                    placeholder="Search Vendors"
                  ></b-form-input>
                  <b-input-group-append>
                    <button class="btn btn-primary btn-block btn-sm">
                      {{ $t("views.payments.transactions.search") }}
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </div> -->
            </div>

            <div class="table-responsive text-center table-custom text-center">
              <b-table
                class="table-centered"
                :items="accountData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :bordered="true"
                sticky-header="500px"
                show-empty
                :empty-text="$t('common.no-records')"
                busy.sync="true"
                hover
              >
                <template v-slot:head()="data">
                  <span v-b-tooltip.hover.top :title="$t(data.field.tooltip)">
                    {{ $t(data.label) }}
                  </span>
                </template>
              </b-table>
              <Spinner v-if="isFetchingTransactions === true"></Spinner>
            </div>

            <div class="row" style="display: none">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination :total-rows="rows" -->
                    <b-pagination
                      v-model="currentPage"
                      :current-page="currentPage"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      size="lg"
      v-model="editModel"
      :title="$t('views.payouts.add_bank_acc')"
      title-class="text-dark font-18"
      no-close-on-backdrop
      hide-footer
    >
      <form @submit.prevent="editVendor" novalidate>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <i class="mdi mdi-account"></i>

              <label for="select_psp">
                {{ $t('views.payouts.selected_psp') }}
              </label>

              <input
                id="select_psp"
                :value="chaiEditVendor.payout_channel"
                type="text"
                class="form-control"
                readonly
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <i class="mdi mdi-account"></i>

              <label for="account_type">
                {{ $t('views.payouts.type_of_acc') }}
                <span class="text-danger mr-1">*</span>
              </label>

              <b-form-select
                name="account_type"
                v-model="chaiEditVendor.account_type"
                :class="{
                  'is-invalid': submitted && $v.chaiEditVendor.account_type.$error,
                }"
                v-if="chaiEditVendor.payout_channel !== 'GBPRIMEPAY_PAYOUT'"
                >
                <b-form-select-option :value="null">{{ $t('common.select_option') }}</b-form-select-option>
                <b-form-select-option value="BANK">Bank Account</b-form-select-option>
                <b-form-select-option v-if="chaiEditVendor.payout_channel !== 'BBL_PAYOUT'" value="CARD">Card</b-form-select-option>
                <b-form-select-option v-if="chaiEditVendor.payout_channel === 'BBL_PAYOUT'" value="PROMPTPAY_NATID">Promptpay - National ID</b-form-select-option>
                <b-form-select-option v-if="chaiEditVendor.payout_channel === 'BBL_PAYOUT'" value="PROMPTPAY_MOBILE">Promptpay - Mobile number</b-form-select-option>
              </b-form-select>
              <input type="text" v-else class="form-control" readonly :value="chaiEditVendor.account_type">
              <div
                v-if="submitted && !$v.chaiEditVendor.account_type.required"
                class="invalid-feedback"
              >
                {{ $t('views.payouts.required_field_msg.bank_account_type') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <i class="mdi mdi-account"></i>

              <label for="name">
                {{ $t("views.payouts.name") }}
                <span class="text-danger mr-1">*</span>
              </label>

              <input
                id="name"
                v-model="chaiEditVendor.name"
                type="text"
                class="form-control"
                placeholder="John Doe"
                :class="{
                  'is-invalid': submitted && $v.chaiEditVendor.name.$error,
                }"
              />
              <div
                v-if="submitted && !$v.chaiEditVendor.name.required"
                class="invalid-feedback"
              >
                
                {{ $t('views.payouts.required_field_msg.vendor_name') }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="email">
                <i class="mdi mdi-email-outline"></i>

                {{ $t("views.payouts.email") }}
                <!-- <span class="text-danger mr-1">*</span> -->
              </label>
              <input
                type="email"
                v-model="chaiEditVendor.email"
                class="form-control"
                placeholder="xyz@mycompanysite.com"
                :class="{
                  'is-invalid': submitted && $v.chaiEditVendor.email.$error,
                }"
              />
              <!-- <div
                v-if="submitted && !$v.chaiEditVendor.email.required"
                class="invalid-feedback"
              >
                Email is required
              </div> -->
              <div
                v-if="submitted && !$v.chaiEditVendor.email.email"
                class="invalid-feedback"
              >
                {{ $t('views.payouts.required_field_msg.email') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="phone">
                <i class="mdi mdi-phone"></i>

                {{ $t("views.payouts.phone") }}

                <span class="text-danger mr-1">*</span>
              </label>
              <VuePhoneNumberInput
                v-model="phoneNumber"
                id="VuePhoneNumber"
                @update="phoneNumberVal = $event"
                valid-color="#1cbb8c"
                :default-country-code="$store.state.auth.currentUser.country"
                :class="(submitted && $v.chaiEditVendor.phone.$error) || (submitted && phoneNumberVal && !phoneNumberVal.isValid) ? `border border-danger rounded` : ``"
                :preferred-countries="['VN', 'TH', 'ID', 'SG', 'KR', 'PH', 'IN', 'MY']"
              />
              <div
                v-if="submitted && $v.chaiEditVendor.phone.$error"
                class="text-danger font-size-12"
              >
                {{ $t('views.payouts.required_field_msg.phone') }}
              </div>
              <div
                v-else-if="submitted && phoneNumberVal && !phoneNumberVal.isValid"
                class="text-danger font-size-12"
              >
                Invalid Phone Number.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="address">
                <i class="mdi mdi-home-map-marker"></i>
                {{ $t("views.payouts.address") }}
                <!-- <span class="text-danger mr-1">*</span> -->
              </label>
              <input
                v-model="chaiEditVendor.address"
                type="tel"
                class="form-control"
                :placeholder="$t('views.payouts.address')"
              />
              <!-- :class="{
                'is-invalid': submitted && $v.chaiEditVendor.address.$error,
              }"
              <div
                v-if="submitted && !$v.chaiEditVendor.address.required"
                class="invalid-feedback"
              >
                Address is required
              </div> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="bankAccountNumber">
                {{ accNumberLabel() }}
                <span class="text-danger">*</span></label
              >
              <b-form-input
                id="bankAccountNumber"
                type="number"
                step="any"
                class="form-control"
                :placeholder="accNumberLabel()"
                v-model="$v.chaiEditVendor.account_no.$model"
                :class="{
                  'is-invalid': submitted && $v.chaiEditVendor.account_no.$error,
                }"
              />
              <div
                v-if="submitted && $v.chaiEditVendor.account_no.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.chaiEditVendor.account_no.required">
                  {{ accNumberError() }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="bankAccountName">
                {{ accNameLabel() }}
                <span class="text-danger">*</span></label
              >
              <b-form-input
                v-model="$v.chaiEditVendor.account_name.$model"
                id="bankAccountName"
                type="text"
                class="form-control"
                :placeholder="accNameLabel()"
                :state='validateState("account_name")'
                :class="{
                  'is-invalid': submitted && $v.chaiEditVendor.account_name.$error,
                }"
              />
              <div
                v-if="submitted && $v.chaiEditVendor.account_name.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.chaiEditVendor.account_name.required">
                  {{ accNameError() }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row"  v-if="!['PROMPTPAY_NATID', 'PROMPTPAY_MOBILE'].includes(chaiEditVendor.account_type)">
          <div class="col-md-6">
            <div class="form-group">
              <label for="bankName">
                <i class="mdi mdi-account"></i>

                {{ $t("views.payouts.bank-name") }}
                <span class="text-danger mr-1">*</span>
              </label>
              <select
                id="bankName"
                name="bankName"
                class="form-control"
                :placeholder="$t('views.payouts.bank_name')"
                v-model="chaiEditVendor.bank_name"
                @change="selectbankcode"
                :class="{
                  'is-invalid':
                    submitted && $v.chaiEditVendor.bank_name.$error,
                }"
              >

             <option :key="index" v-for="(option, index) in banksList" :data-key="option.bank_no" :value="option.bank_name" >{{ option.bank_name }}</option>
              </select>
              <div
                v-if="submitted && !$v.chaiEditVendor.bank_name.required"
                class="invalid-feedback"
              >
                {{ $t('views.payouts.required_field_msg.vendor_bank_name') }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="bankNumber">
                <i class="mdi mdi-account"></i>

                {{ $t("views.payouts.bank-number") }}
                <span class="text-danger mr-1">*</span>
              </label>
              <input
                id="bankNumber"
                type="text"
                class="form-control"
                :placeholder="$t('views.payouts.bank_no')"
                v-model="chaiEditVendor.bank_no"
                :class="{
                  'is-invalid': submitted && $v.chaiEditVendor.bank_no.$error,
                }"
              />
              <div
                v-if="submitted && !$v.chaiEditVendor.bank_no.required"
                class="invalid-feedback"
              >
                {{ $t('views.payouts.required_field_msg.vendor_bank_number') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="chaiEditVendor.payout_channel == 'APPOTAPAY_PAYOUT'">
          <div class="col-md-6">
            <div class="form-group">
              <label for="fee_payer">
                <i class="mdi mdi-account-circle"></i>
                {{ $t('views.payouts.fee_payer') }}
                <span class="text-danger mr-1">*</span>
              </label>
              <b-form-select
                name="fee_payer"
                v-model="chaiEditVendor.fee_payer"
                :class="{
                  'is-invalid': submitted && $v.chaiEditVendor.fee_payer.$error,
                }">
                <b-form-select-option :value="null">{{ $t("common.select_option") }}</b-form-select-option>
                <b-form-select-option value="SENDER">SENDER</b-form-select-option>
                <b-form-select-option value="RECEIVER">RECEIVER</b-form-select-option>
              </b-form-select>
              <div
                v-if="submitted && !$v.chaiEditVendor.fee_payer.required"
                class="invalid-feedback"
              >
                {{ $t('views.payouts.required_field_msg.fee_payer') }}
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <b-button class="mr-1" variant="light" @click="editModel = false">{{ $t('button.close') }}</b-button>
          <button type="submit" class="btn btn-primary ml-1">
            {{ $t("button.save") }}
          </button>
        </div>
      </form>
    </b-modal>
    <div>
      <b-modal
        centered
        :title="$t('views.payouts.bank_acc_creation')"
        v-model="createError"
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
      >
        <p>{{ errorMessage }}</p>
        <p>{{ $t('views.payouts.account_creation_failed') }}</p>
      </b-modal>
    </div>

    <div>
      <b-modal
        centered
        :title="$t('views.payouts.bank_acc_creation')"
        v-model="successfulMessage"
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
      >
        <p>{{ $t('views.payouts.account_creation_success') }}</p>
      </b-modal>
    </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep #transaction-filter-collapse > .multiselect__tag {
  background-color: #41b883;
}
</style>